<template>
  <div class="remember">
    <div class="remember-book">
      <p v-html="bookname"></p>
      <p>词汇速记</p>
    </div>
    <div class="remember-list" v-if="list.length > 0">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="remember-list-item"
        @click="clickItem(index)"
      >
        <img src="https://res.cdn.quyixian.com/front/playaudio.png" alt="" />
        <p v-html="item.word"></p>
      </div>
    </div>
    <div v-else>无数据</div>
    <audio id="audio" ref="audio" style="height: 0" :src="src"></audio>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
export default {
  name: "remember",
  data() {
    return {
      resid: this.$route.query.rid == undefined ? "" : this.$route.query.rid, //资源ID
      bookid: this.$route.query.bid == undefined ? "" : this.$route.query.bid, //图书ID
      resname:
        this.$route.query.rname == undefined ? "" : this.$route.query.rname, //资源名称
      bookname:
        this.$route.query.bname == undefined ? "" : this.$route.query.bname, //图书名称
      src: "",
      list: [],
      audiocurrNumber: 0, //当前点击段落序号
      audioStart: 0, //段落开始时间
      audioEnd: 0, //段落结束时间
    };
  },
  mounted() {
    const that = this;
    if (this.resid) {
      this.getremember();
    } else {
      //数据错误
    }

    //监听全文播放音频
    document
      .getElementById("audio")
      .addEventListener("timeupdate", function () {
        //----------------测试展示总音频播放进度，当前时间，总时间
        let current = this.currentTime;
        let duration = this.duration;
        //----------------
        //输出信息
        console.log("当前毫秒：" + current + ",总毫秒：" + duration);
        //判断当前播放时间与段落结尾时间
        if (that.audioEnd > 0 && that.audioEnd < current) {
          this.pause();
        }
      });
  },
  methods: {
    //列表朗读切换
    clickItem(index) {
      this.audiocurrNumber = index; //记录当前点击的列表序列
      this.audioStart = this.list[index].startTime;
      this.audioEnd = this.list[index].endTime;
      let audio = this.$refs.audio;
      audio.pause();
      setTimeout(() => {
        audio.currentTime = this.audioStart;
        audio.play();
      }, 500);
    },
    //播放，暂停
    togglePlaying() {
      let audio = this.$refs.audio;
      if (audio.paused) {
        setTimeout(() => {
          audio.play();
        }, 100);
      } else {
        audio.pause();
      }
    },

    //获取速记数据
    getremember() {
      let params = {
        resid: this.resid,
      };
      this.$api.mary.getMemoryList(params).then((res) => {
        console.log(res);
        let { tag, data, message } = res;
        if (tag == 1) {
          this.src = data.filePath;
          this.list = data.memoryList;
        } else {
          console.log(message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.remember {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.28rem;
  &-book {
    width: 70%;
    text-align: center;
    font-weight: bold;
    margin: 0.5rem 0 0.3rem 0;
  }
  &-list {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #f3f3f3;
      width: 80%;
      border-radius: 1rem;
      padding: 0.4rem;
      margin: 0.3rem 0;

      img {
        width: 0.44rem;
        height: 0.44rem;
        margin-right: 0.15rem;
      }
      P {
        font-size: 0.32rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
